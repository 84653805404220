import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://bfb1feb9becc36f4d1b65991833beb21@o4506893267435520.ingest.us.sentry.io/4506893271367680",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});